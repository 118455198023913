<script setup lang="ts">
</script>

<template>
  <div class="hot-banner">
    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
      <path d="M27.7895 20.473C27.7895 26.6556 23.9312 29.3686 20.9498 30.2835C20.314 30.4786 19.8962 29.7477 20.2765 29.2088C21.5739 27.3702 23.0737 24.565 23.0737 21.9262C23.0737 19.0916 20.6509 15.745 18.7589 13.6809C18.3264 13.209 17.5719 13.5213 17.5484 14.1569C17.4702 16.2629 17.1332 19.0805 15.68 21.2888C15.446 21.6442 14.9495 21.6737 14.683 21.3413C14.2289 20.775 13.7749 20.0755 13.3208 19.5229C13.0762 19.2252 12.6342 19.2211 12.3521 19.4845C11.2522 20.5115 9.7123 22.113 9.7123 24.106C9.7123 25.4573 10.2432 26.874 10.8422 28.0138C11.1717 28.6409 10.5858 29.3956 9.95161 29.0675C7.32453 27.708 4.21054 24.9541 4.21054 20.473C4.21054 15.9006 10.5625 9.56651 12.9879 3.58238C13.3706 2.63811 14.55 2.1944 15.3704 2.81061C20.3384 6.5425 27.7895 13.7565 27.7895 20.473Z" fill="url(#paint0_linear_29706_273266)"></path>
      <defs>
        <linearGradient
          id="paint0_linear_29706_273266"
          x1="16"
          y1="2.52631"
          x2="16"
          y2="30.3158"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#FF66A9"></stop>
          <stop offset="1" stop-color="#E30365"></stop>
        </linearGradient>
      </defs>
    </svg>
    <div class="hot-banner-text-container">
      <span class="hot-banner-top-text">Запись открыта!</span>
      <span class="hot-banner-bottom-text">Свободное время быстро заканчивается</span>
    </div>
  </div>
</template>

<style scoped lang="scss">
.hot-banner {
  margin-top: 16px;

  display: flex;
  align-items: flex-start;
  gap: 10px;
  padding: 12px;

  border-radius: 15px;
  background-color: #FFF1F7;
}
.hot-banner-text-container {
  display: flex;
  flex-direction: column;
  gap: 2px;
}
.hot-banner-top-text {
  color: #E30365;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px;
}
.hot-banner-bottom-text {
  color: #E93382;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  @media (min-width: 400px) {
    text-wrap: nowrap;
  }
}
</style>
